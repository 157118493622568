<template>
    <div class="content-main">
        <!-- <img class="img" src="../assets/tuanduiyoushi.png" /> -->
        <div class="items">
            <div class="title line1">
                <h1>团队优势</h1>
                <h2>TEAM ADVANTAGE</h2>
            </div>
            <div class="label red line1">
                <div class="number">
                    <span>01</span>
                </div>
                <div class="sub-title">年轻且经验丰富的团队</div>
                <div
                    class="sub-desc"
                >曾服务于联想、富士、花王、ASUS、联通、佳能、大塚、三生制药、佳能等品牌客户。创意团队是一群年轻且具备大型4A广告公司经验的团队。</div>
            </div>
            <div class="label blue line1">
                <div class="number">
                    <span>02</span>
                </div>
                <div class="sub-title">多家连锁餐饮品牌服务经验</div>
                <div
                    class="sub-desc"
                >服务品牌包括德克士、达美乐、统一等10家+餐饮连锁。积累了大量从会员营销、UI设计、新媒体运营到整合营销经验及未来的元宇宙营销规划。</div>
            </div>
            <div class="label red line1">
                <div class="number">
                    <span>03</span>
                </div>
                <div class="sub-title">10年+品牌游戏创意开发经验</div>
                <div
                    class="sub-desc"
                >从早起的WEB游戏和UI交互设计到当前的游戏次奥程序，团队始终坚持以技术革新为驱动，辅以大量游戏执行经验的积累，为品牌创造属于自己的小游戏。</div>
            </div>
            <div class="label blue">
                <div class="number">
                    <span>04</span>
                </div>
                <div class="sub-title">技术实力派</div>
                <div
                    class="sub-desc"
                >行业顶尖技术团队，从cocos creator 游戏引擎开发人员到数据库应用开发，再到AR和VR交互体验开发团队。创始人现任亚太电子竞技委员会委员。</div>
            </div>
            <div class="label red">
                <div class="number">
                    <span>05</span>
                </div>
                <div class="sub-title">可定制化直播基地</div>
                <div class="sub-desc">公司直播基地位于上海，占地700余平方米，现有直播间10+，可为品牌长期提供定制化直播场地服务。</div>
            </div>
            <div class="label blue">
                <div class="number">
                    <span>06</span>
                </div>
                <div class="sub-title">营销标签采集</div>
                <div
                    class="sub-desc"
                >坚持广告与数据结合的创意才能获得真实的转化。我们与大型标签采集系统合作商拥有长期战略协议和使用经验，为客户把每一次的点击转化为品牌数据留存。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.content-main {
    width: 1200px;
    height: 633px;
    background: url("../assets/tuanduiyoushi-bg.png") no-repeat center center;
    background-size: 100% auto;
}

.items {
    width: 100%;
    height: 100%;
    list-style: none;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
}

.items .title {
    padding-top: 20px;
    margin: 20px;
    width: 250px;
    height: 250px;
    box-sizing: border-box;
}
.items .label {
    width: 262px;
    height: 262px;
    border-radius: 30px;
    /* opacity: 0.9; */
    margin: 20px;
    padding: 20px;
    box-sizing: border-box;
}

.line1 {
    margin-top: 40px !important;
}

h1 {
    font-size: 60px;
    color: #fff;
    /* text-shadow: #fff; */
    box-sizing: border-box;
}
h2 {
    font-size: 20px;
    color: #fff;
}
.red {
    background-color: #900;
}
.blue {
    background-color: #2e58e9;
}
.label h1 {
    text-align: right;
    border-bottom: 1px solid #fff;
}
.label .number {
    overflow: hidden;
}
.label .number span {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    border-bottom: 1px solid #fff;
    float: right;
    display: block;
}

.label .sub-title {
    margin-top: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}

.label .sub-desc {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
}
</style>